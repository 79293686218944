import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <header>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <a href="/">
                  <img className="logo" src="img/logo.png" alt="" />
                </a>
              </div>
            </div>
            {this.props.isHome ? (
              <a className="header_btn middle_text" href="/details">Details</a>
            ):(
              <a className="header_btn middle_text" href="/">Home</a>
            ) }
          </div>
        </header>
      );
    }
  }

  export default Header;