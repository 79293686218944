import React, { Component } from 'react';
import Frame from "../../components/frame";
import TronWeb from 'tronweb';
import {withRouter,Link} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {dataDetailsApi} from '../../utils';

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      isConnected: false, 
      tronWeb: {
        installed: false,
        loggedIn: false
      },
      userDetails:{
        leftTeam:0,
        rightTeam:0,
        leftDirect:0,
        rightDirect:0,
        walletBalance:0,
        uplineIncome:0,
        downlineIncome:0,
        binaryIncome:0,
        sponsorIncome:0,
        totalPackage:0,
        totalIncome:0
      },
      subscriptionList:null,
      withdrawList:null,
      incomeList:null,
      binaryPayoutLog:null,
      sponsorIncomeLog:null,
      remaining_left_payout:0,
      remaining_right_payout:0,
      left_user_count:0,        
      right_user_count:0,
      left_subscription_count:0,
      right_subscription_count:0,
    }
  }

  async componentDidMount(){
    await new Promise(resolve => {
    const tronWebState = {
        installed: !!window.tronWeb,
        loggedIn: window.tronWeb && window.tronWeb.ready
    };

    if(tronWebState.installed) {
        this.setState({
            tronWeb:
            tronWebState
        });

        return resolve();
    }

    let tries = 0;

    const timer = setInterval(() => {
        if(tries >= 10) {
            const TRONGRID_API = 'https://api.shasta.trongrid.io';

            window.tronWeb = new TronWeb(
                TRONGRID_API,
                TRONGRID_API,
                TRONGRID_API
            );

            this.setState({
                tronWeb: {
                    installed: false,
                    loggedIn: false
                }
            });

            clearInterval(timer);
            return resolve();
        }

        tronWebState.installed = !!window.tronWeb;
        tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;

        if(!tronWebState.installed)
            return tries++;

        this.setState({
            tronWeb: tronWebState
        });

        resolve();
    }, 100);
    });

    if(!this.state.tronWeb.loggedIn) {
        window.tronWeb.on('addressChanged', () => {
            if(this.state.tronWeb.loggedIn)
                return;

            this.setState({
                tronWeb: {
                    installed: true,
                    loggedIn: true
                }
            });
        });
    }
    
    this.dataDetailsApi();

    window.tronWeb.on('addressChanged', () => {
        if(this.state.tronWeb.loggedIn)
        this.dataDetailsApi();
    });
  }

  paginate = (
    totalItems,
    currentPage = 1,
    pageSize = 10,
    maxPages = 10
  ) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
  }

  getPaginationData = async(url,page,type,current_page,last_page) => {
    if(!url && !page){
      return true;
    }
    this.setState({loading:true});
    this.dataDetailsApi(url,page,type);
  }

  refreshApiData = async() => {
    this.setState({loading:true},()=>{
      this.dataDetailsApi();
    });
  }

  dataDetailsApi = async(url=null,page=null,type=null)=>{
    if(!window.tronWeb.defaultAddress.base58){
      return false;
    }
    const data = await dataDetailsApi(window.tronWeb.defaultAddress.base58,url,page,type);
    //const data = await dataDetailsApi('TTgH6SS8n1EBmH9AiT6cu8d1QvENhF7GXQ',url,page,type);
    console.log('dataDetailsApi',data);
    if(data.status){
      let totalIncome = parseInt(data.data.uplineIncome) +
      parseInt(data.data.downlineIncome) + parseInt(data.data.binaryIncome);
      this.setState(({userDetails})=>
        ({userDetails : {...userDetails,
          leftTeam:data.data.leftTeam,
          rightTeam:data.data.rightTeam,
          leftDirect:data.data.leftDirect,
          rightDirect:data.data.rightDirect,
          walletBalance:data.data.walletBalance,
          uplineIncome:data.data.uplineIncome,
          downlineIncome:data.data.downlineIncome,
          binaryIncome:data.data.binaryIncome,
          sponsorIncome:data.data.sponsorIncome,
          totalPackage:data.data.totalPackage,
          totalIncome:totalIncome
        }})
      );

      let subscriptionList = data.data.subscriptionList;
      let subscriptionListPagination = this.paginate(subscriptionList.total,subscriptionList.current_page,subscriptionList.per_page,10);
      subscriptionList.pages = subscriptionListPagination.pages;

      let withdrawList = data.data.withdrawList;
      let withdrawListPagination = this.paginate(withdrawList.total,withdrawList.current_page,withdrawList.per_page,10);
      withdrawList.pages = withdrawListPagination.pages;

      // let incomeList = data.data.incomeList;
      // let incomeListPagination = this.paginate(incomeList.total,incomeList.current_page,incomeList.per_page,10);
      // incomeList.pages = incomeListPagination.pages;

      let binaryPayoutLog = data.data.binaryPayoutLog;
      let binaryPayoutLogPagination = this.paginate(binaryPayoutLog.total,binaryPayoutLog.current_page,binaryPayoutLog.per_page,10);
      binaryPayoutLog.pages = binaryPayoutLogPagination.pages;

      // let sponsorIncomeLog = data.data.sponsorIncomeLog;
      // let sponsorIncomeLogPagination = this.paginate(sponsorIncomeLog.total,sponsorIncomeLog.current_page,sponsorIncomeLog.per_page,10);
      // sponsorIncomeLog.pages = sponsorIncomeLogPagination.pages;
      
      this.setState({
        subscriptionList:data.data.subscriptionList,
        withdrawList:data.data.withdrawList,
        //incomeList:data.data.incomeList,
        sponsorIncomeLog:data.data.sponsorIncomeLog,
        binaryPayoutLog:data.data.binaryPayoutLog,
        remaining_left_payout:data.data.remaining_left_payout,
        remaining_right_payout:data.data.remaining_right_payout,
        left_user_count:data.data.left_user_count,
        right_user_count:data.data.right_user_count,
        left_subscription_count:data.data.left_subscription_count,
        right_subscription_count:data.data.right_subscription_count,
        loading:false        
      });
    }else{
      let defaultError = "Some error occurred!";
      if(data.hasOwnProperty('error')){
        defaultError = data.error;
      }
      this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      this.setState({loading:false});
    }
  }
  render() {
    let this2 = this;
    return (
      <Frame withHeader={true} withFooter={true} withLeftpart={true} isHome={false}>
        <section className="pt_40 pb_40">
          <div className="container">
            {this.state.loading ? (
              <div className="loader_body">
                <div className="loader">
                  <div className="line left"></div>
                  <div className="ball"></div>
                  <div className="line right"></div>
                </div>
              </div>
            ) : (null)}
            <div className="md_container">
              <div className="row cus_row">
                <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <h4>Total Bonus </h4>
                        <h6>Amount </h6>
                        <h5>{parseFloat(this.state.userDetails.totalIncome).toFixed(4)} TRX</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <h4>Sponsor Bonus </h4>
                        <h6>Amount </h6>
                        <h5>{parseFloat(this.state.userDetails.sponsorIncome).toFixed(4)} TRX</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <h4>Upline Bonus</h4>
                        <h6>Net Profit </h6>
                        <h5>{parseFloat(this.state.userDetails.uplineIncome).toFixed(4)} TRX</h5>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <h4>Downline Bonus </h4>
                        <h6>Net Profit </h6>
                        <h5>{parseFloat(this.state.userDetails.downlineIncome).toFixed(4)} TRX</h5>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <h4>Binary Bonus</h4>
                        <h6>Net Profit </h6>
                        <h5>{parseFloat(this.state.userDetails.binaryIncome).toFixed(4)} TRX</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <h4>Your Subscription</h4>
                        <h6>Amount</h6>
                        <h5>{parseFloat(this.state.userDetails.totalPackage).toFixed(4)} TRX</h5>
                      </div>
                    </div>
                  </div>
                </div>                
              
              </div>
            </div>
          </div>
        </section>

        <section className="pb_40">
          <div className="container">
            <div className="md_container">
              <div className="all_heading heading_c_1 text-center">
                <h2>Subscription History 
                  {/* <span className="float-right">
                    <a disabled={this.state.loading} onClick={this.refreshApiData}><img className="refresh_btn" src="img/refresh.png" alt="" /></a>
                  </span> */}
                  {/* <span className="float-right red_text">
                    <a title="Refresh">
                      <i disabled={this.state.loading} onClick={this.refreshApiData} class="fa fa-refresh" style={{fontSize:20, cursor:this.state.loading?'initial':'pointer'}}></i>
                    </a>
                  </span> */}
                </h2>
              </div>
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Total Amount </th>
                      <th>Activation Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.subscriptionList ? (
                      this.state.subscriptionList.data.length>0 ? (
                        this.state.subscriptionList.data.map(function(val, index){                        
                          return (
                            <tr key={`pah${index}`}>
                              <td className="text-info">{parseFloat(val.total_amount).toFixed(4)} TRX</td>
                              <td>{val.created_at}</td>
                            </tr>
                          )
                        })
                      ):(
                        <tr>
                          <td colSpan="2" className="text-center">No Data Available!</td>
                        </tr>
                      )
                    ) : (
                    <tr>
                      <td colSpan="4" className="text-center">No Data Available!</td>
                    </tr>
                    )}
                  </tbody>
                </table>
                {this.state.subscriptionList ? (
                  <div className="text-center">
                    <ul className="table_pagination mb-0">
                      <li  style={{cursor:this.state.subscriptionList.prev_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.subscriptionList.prev_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)}><i class="fa fa-angle-double-left"></i></a>
                      </li>
                      {this.state.subscriptionList.pages.length>0 ? (
                        this.state.subscriptionList.pages.map(function(val, index){
                          let className = "";
                          if(val == this2.state.subscriptionList.current_page){
                            className = "active";
                          }
                          return (
                            <li key={`pahp${index}`} className={className} style={{cursor:val != this2.state.subscriptionList.current_page?'pointer':'initial'}}>
                              <a onClick={()=>this2.getPaginationData(null,'subs_page',val,null,null)} >{val}</a>
                            </li>
                          )
                        })
                      ):(null)}
                      <li style={{cursor:this.state.subscriptionList.next_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.subscriptionList.next_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)} ><i class="fa fa-angle-double-right"></i></a>
                      </li>
                    </ul>
                  </div>
                ):(null)}
              </div>
            </div>
          </div>
        </section>        

        <section className="pb_40">
          <div className="container">
            <div className="md_container">
              <div className="all_heading heading_c_2 text-center">
                <h2>Withdraw History</h2>
                <span>
                  Available Balance: <b>{parseFloat(this.state.userDetails.walletBalance).toFixed(4)} TRX</b>
                </span>
              </div>
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th># Transaction Id </th>
                      <th>Amount From </th>
                      <th>Withdrawable Amount </th>
                      <th>Reinvestment Amount </th>
                      <th>Remarks</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>                    
                    {this.state.withdrawList ? (
                      this.state.withdrawList.data.length>0 ? (
                        this.state.withdrawList.data.map(function(val, index){                        
                          return (
                            <tr key={`wl${index}`}>
                              <td> {val.t_hash} </td>
                              <td className="text-success"> {parseFloat(val.amount_from).toFixed(4)} {val.coin_type}</td>
                              <td className="text-success"> {parseFloat(val.withdrawable_amount).toFixed(4)} {val.coin_type}</td>
                              <td className="text-success"> {parseFloat(val.reinvestment_amount).toFixed(4)} {val.coin_type}</td>                                                       
                              <td> {val.remarks} </td>
                              <td> {val.status} </td>
                              <td> {val.created_at} </td>
                            </tr>
                          )
                        })
                      ):(
                        <tr>
                          <td colSpan="7" className="text-center">No Data Available!</td>
                        </tr>
                      )
                    ) : (
                    <tr>
                      <td colSpan="7" className="text-center">No Data Available!</td>
                    </tr>
                    )}
                  </tbody>
                </table>
                {this.state.withdrawList && this.state.withdrawList.data.length>0 ? (
                  <div className="text-center">
                    <ul className="table_pagination mb-0">
                      <li style={{cursor:this.state.withdrawList.prev_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.withdrawList.prev_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}><i class="fa fa-angle-double-left"></i></a>
                      </li>
                      {this.state.withdrawList.pages.length>0 ? (
                        this.state.withdrawList.pages.map(function(val, index){
                          let className = "";
                          if(val == this2.state.withdrawList.current_page){
                            className = "active";
                          }
                          return (
                            <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.withdrawList.current_page?'pointer':'initial'}}>
                              <a onClick={()=>this2.getPaginationData(null,'withdraw_page',val,null,null)}>{val}</a>
                            </li>
                          )
                        })
                      ):(null)}
                      <li style={{cursor:this.state.withdrawList.next_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.withdrawList.next_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}><i class="fa fa-angle-double-right"></i></a>
                      </li>
                    </ul>
                  </div>
                ):(null)}
              </div>
            </div>
          </div>
        </section>

        <section className="pb_40">
          <div className="container">
            <div className="md_container">
              <div className="all_heading heading_c_1 text-center">
                <h2>Binary Payout Log</h2>
              </div>
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Total Subscription </th>
                      <th>Distribution Amount</th>
                      <th>Total Matching Points</th>
                      <th>Your Matching Points </th>
                      <th>Unit Value </th>
                      <th>Payout</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.binaryPayoutLog ? (
                      this.state.binaryPayoutLog.data.length>0 ? (
                        this.state.binaryPayoutLog.data.map(function(val, index){                        
                          return (
                            <tr key={`bll${index}`}>
                              <td className="text-success"> {parseFloat(val.calculate_total_subs_amount).toFixed(4)} TRX</td>
                              <td className="text-success"> {parseFloat(val.calculate_total_bonus_amount).toFixed(4)} TRX</td>
                              <td className="text-success"> {parseFloat(val.calculate_all_user_payout).toFixed(4)} TRX</td>
                              <td className="text-success"> {parseFloat(val.total_payout).toFixed(4)} TRX</td>                            
                              <td className="text-success"> {parseFloat(val.per_commission).toFixed(4)} TRX</td>
                              <td className="text-success"> {parseFloat(val.bonus_amount).toFixed(4)} TRX</td>
                              <td> {val.created_at} </td>
                            </tr>
                          )
                        })
                      ):(
                        <tr>
                          <td colSpan="7" className="text-center">No Data Available!</td>
                        </tr>
                      )
                    ) : (
                    <tr>
                      <td colSpan="7" className="text-center">No Data Available!</td>
                    </tr>
                    )}
                  </tbody>
                </table>
                {this.state.binaryPayoutLog && this.state.binaryPayoutLog.data.length>0 ? (
                  <div className="text-center">
                    <ul className="table_pagination mb-0">
                      <li style={{cursor:this.state.binaryPayoutLog.prev_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.prev_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)}><i class="fa fa-angle-double-left"></i></a>
                      </li>
                      {this.state.binaryPayoutLog.pages.length>0 ? (
                        this.state.binaryPayoutLog.pages.map(function(val, index){
                          let className = "";
                          if(val == this2.state.binaryPayoutLog.current_page){
                            className = "active";
                          }
                          return (
                            <li key={`bllp${index}`} className={className} style={{cursor:val != this2.state.binaryPayoutLog.current_page?'pointer':'initial'}}>
                              <a onClick={()=>this2.getPaginationData(null,'binary_page',val,null,null)}>{val}</a>
                            </li>
                          )
                        })
                      ):(null)}
                      <li style={{cursor:this.state.binaryPayoutLog.next_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.next_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)}><i class="fa fa-angle-double-right"></i></a>
                      </li>
                    </ul>
                  </div>
                ):(null)}
              </div>
            </div>
          </div>
        </section>

        <section className="pb_40">
          <div className="container">
            <div className="md_container">
              <div className="all_heading heading_c_2 text-center">
                <h2>Sponsor Income</h2>
              </div>
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th> Level </th>
                      <th> Count </th>
                      <th> Invest Amount </th>
                      <th> Amount </th>
                    </tr>
                  </thead>
                  <tbody>                    
                    {this.state.sponsorIncomeLog ? (
                      this.state.sponsorIncomeLog.length>0 ? (
                        this.state.sponsorIncomeLog.map(function(val, index){                        
                          return (
                            <tr key={`si${index}`}>
                              <td className="text-success"> Level-{val.level} </td>
                              <td className="text-success"> {val.user_count}</td>
                              <td className="text-success"> {parseFloat(val.investment).toFixed(2)} TRX</td>
                              <td className="text-success"> {parseFloat(val.amount).toFixed(2)} TRX </td>
                            </tr>
                          )
                        })
                      ):(
                        <tr>
                          <td colSpan="7" className="text-center">No Data Available!</td>
                        </tr>
                      )
                    ) : (
                    <tr>
                      <td colSpan="7" className="text-center">No Data Available!</td>
                    </tr>
                    )}
                  </tbody>
                </table>
                {/* {this.state.sponsorIncomeLog && this.state.sponsorIncomeLog.data.length>0 ? (
                  <div className="text-center">
                    <ul className="table_pagination mb-0">
                      <li style={{cursor:this.state.sponsorIncomeLog.prev_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.sponsorIncomeLog.prev_page_url,null,null,this.state.sponsorIncomeLog.current_page,this.state.sponsorIncomeLog.last_page)}><i class="fa fa-angle-double-left"></i></a>
                      </li>
                      {this.state.sponsorIncomeLog.pages.length>0 ? (
                        this.state.sponsorIncomeLog.pages.map(function(val, index){
                          let className = "";
                          if(val == this2.state.sponsorIncomeLog.current_page){
                            className = "active";
                          }
                          return (
                            <li key={`sip${index}`} className={className} style={{cursor:val != this2.state.sponsorIncomeLog.current_page?'pointer':'initial'}}>
                              <a onClick={()=>this2.getPaginationData(null,'sponsor_income_page',val,null,null)}>{val}</a>
                            </li>
                          )
                        })
                      ):(null)}
                      <li style={{cursor:this.state.sponsorIncomeLog.next_page_url?'pointer':'initial'}}>
                        <a onClick={()=>this.getPaginationData(this.state.sponsorIncomeLog.next_page_url,null,null,this.state.sponsorIncomeLog.current_page,this.state.sponsorIncomeLog.last_page)}><i class="fa fa-angle-double-right"></i></a>
                      </li>
                    </ul>
                  </div>
                ):(null)} */}
              </div>
            </div>
          </div>
        </section>

        <div className="pb_40">
          <div className="container">
            <div className="md_container">
              <div className="row cus_row">
                  
                <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <img className="depo_icon" src="img/icon8.png" alt="" />
                        <h4>Total Left Point </h4>
                        <h5>{this.state.left_subscription_count?this.state.left_subscription_count:0}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                  
                <div className="col-md-4 col-sm-4 col-lg-4 col-12">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <img className="depo_icon" src="img/icon10.png" alt="" />
                        <h4>Paid Left Point </h4>
                        <h5>{this.state.left_user_count}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                  
                <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <img className="depo_icon" src="img/icon9.png" alt="" />
                        <h4>Remaining Left Point </h4>
                        <h5>{parseFloat(this.state.remaining_left_payout).toFixed(4)}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <img className="depo_icon" src="img/icon12.png" alt="" />
                        <h4>Total Right Point </h4>
                        <h5>{this.state.right_subscription_count?this.state.right_subscription_count:0}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4 col-12">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <img className="depo_icon" src="img/icon13.png" alt="" />
                        <h4>Paid Right Point </h4>
                        <h5>{this.state.right_user_count}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <img className="depo_icon" src="img/icon11.png" alt="" />
                        <h4>Remaining Right Point  </h4>
                        <h5>{parseFloat(this.state.remaining_right_payout).toFixed(4)}</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <h4>Left Team</h4>
                        {/* <h6>Amount</h6> */}
                        <h5>{this.state.userDetails.leftTeam}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <h4>Right Team</h4>
                        {/* <h6>Amount</h6> */}
                        <h5>{this.state.userDetails.rightTeam}</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <h4>Left Referrals</h4>
                        {/* <h6>Amount</h6> */}
                        <h5>{this.state.userDetails.leftDirect}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-6">
                  <div className="border_line">
                    <div className="border_bg">
                      <div className="dark_bg text-center">
                        <h4>Right Referrals</h4>
                        {/* <h6>Amount</h6> */}
                        <h5>{this.state.userDetails.rightDirect}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>        

      </Frame>
    );
  }
}

const useStyles = {
  root: {
    flexGrow: 1
  },
}

export default withRouter(withStyles(useStyles)(withSnackbar(Details)));

  //export default Details;