import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter,Link} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CircularProgress from '@material-ui/core/CircularProgress';

import TronWeb from 'tronweb';
import { utils as Utils, SunMoon } from '../../utils';
import {tronApi,communityDetailsApi,withdrawApi,missingTransactionApi,networkSettingApi} from '../../utils';
import ReCAPTCHA from "react-google-recaptcha";
const defaultAmountArr = ['500','1000','2000','3000','5000','15000','50000'];

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:false,
        isConnected: false, 
        tronWeb: {
          installed: false,
          loggedIn: false
        },
        contractInfo: {
          contractAddress:'-',
          contractAddressLinkto: null,
          ReferredByContent:null,
          ReferredBy: null,
        },
        userInfo:{
          YourAddress: null,
          YourAddressfull:null,
          TotalWithdrawn:0,
          TotalDeposits:0,
          checkpoint:0
        },
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        amountTrx:0,
        globalInfo:{
          user_count: 0,
          withdraw_amount:0,
          investment_amount:0
        },
        userDetails:{
          wallet_balance:0,
          upline_income:0,
          downline_income:0,
          binaryIncome:0,
          sponsorIncome:0,
          totalIncome:0,
          user_withdraw:0,
          user_investment:0,
          userReferredBy:'-',
          userReferredByFull:'-',
          maxUplineIncomeLevel:0,
          maxDownlineIncomeLevel:0,
          maxDownlineIncomeLevel:0,
          upline_percentage:0,
          downline_percentage:0,
        },
        communityDetails:{
          main_user: null,
          upline_list:[],
          downline_list:[]
        },
        withdrawRule:{
          reinvestment_percentage:0,
          withdraw_percentage:0
        },
        sponsorDetails:[],
        loadingWithdrawl:false,
        withdrawlAmount:0,
        captchaValueWithdrawal:null,
        loadingMissingTrsn:false,
        transaction_hash:'',
        captchaValueMT:null,
        loadingNSetting:false,
        default_leg:'',
        getValidAddress:null,
        inputValidAddress:null,
        editAddress:true
      }
    }

    async componentDidMount(){
      await new Promise(resolve => {
      const tronWebState = {
          installed: !!window.tronWeb,
          loggedIn: window.tronWeb && window.tronWeb.ready
      };
  
      if(tronWebState.installed) {
          this.setState({
              tronWeb:
              tronWebState
          });
  
          return resolve();
      }
  
      let tries = 0;
  
      const timer = setInterval(() => {
          if(tries >= 10) {
              const TRONGRID_API = 'https://api.shasta.trongrid.io';
  
              window.tronWeb = new TronWeb(
                  TRONGRID_API,
                  TRONGRID_API,
                  TRONGRID_API
              );
  
              this.setState({
                  tronWeb: {
                      installed: false,
                      loggedIn: false
                  }
              });
  
              clearInterval(timer);
              return resolve();
          }
  
          tronWebState.installed = !!window.tronWeb;
          tronWebState.loggedIn = window.tronWeb && window.tronWeb.ready;
  
          if(!tronWebState.installed)
              return tries++;
  
          this.setState({
              tronWeb: tronWebState
          });
  
          resolve();
      }, 100);
      });
  
     
  
      if(!this.state.tronWeb.loggedIn) {
          window.tronWeb.on('addressChanged', () => {
              if(this.state.tronWeb.loggedIn)
                  return;
  
              this.setState({
                  tronWeb: {
                      installed: true,
                      loggedIn: true
                  }
              });
          });
      }
  
      setInterval(()=>{
        this.fetchData();
      },3000);

      this.fetchSunMoonData();
      
      this.communityDetailsApi(1);
  
      window.tronWeb.on('addressChanged', () => {
          if(this.state.tronWeb.loggedIn)
          this.fetchData();
          this.communityDetailsApi(1);
      });
    }
  
    fetchData= async()=>{
      await Utils.setTronWeb(window.tronWeb);
  
      const contactInfo = await Utils.contract.contractInfo().call();
      const contractBalance = await window.tronWeb.trx.getBalance(Utils.address);
      const contractAddress = Utils.address;
  
      this.setState(({contractInfo})=>
        ({contractInfo : {...contractInfo, 
        contractAddress:contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length-5, contractAddress.length),
        contractAddressLinkto:contractAddress,
        contractBalance: window.tronWeb.fromSun(contractBalance),
        }})
      );
  
      const users = await Utils.contract.users(window.tronWeb.defaultAddress.base58).call();
      const referrer = window.tronWeb.address.fromHex(users.referrer);
      const checkpoint = users.checkpoint;
      const checkpointVal = window.tronWeb.toDecimal(checkpoint._hex);
      const getUserTotalWithdrawn = await Utils.contract.getUserTotalWithdrawn(window.tronWeb.defaultAddress.base58).call();
      const TotalWithdrawn = window.tronWeb.toDecimal(getUserTotalWithdrawn._hex);
      
      const getUserTotalDeposits = await Utils.contract.getUserTotalDeposits(window.tronWeb.defaultAddress.base58).call();
      const TotalDeposits = window.tronWeb.toDecimal(getUserTotalDeposits._hex);
      
      
      this.setState(({userInfo})=>
        ({userInfo : {...userInfo, 
          YourAddress: window.tronWeb.defaultAddress.base58.slice(0, 10) + '.....' + window.tronWeb.defaultAddress.base58.slice(window.tronWeb.defaultAddress.base58.length-5, window.tronWeb.defaultAddress.base58.length),
          YourAddressfull:window.tronWeb.defaultAddress.base58,
          TotalWithdrawn:window.tronWeb.fromSun(TotalWithdrawn),
          TotalDeposits:window.tronWeb.fromSun(TotalDeposits),
          checkpoint:checkpointVal
        }})
      );
  
      this.setState(({contractInfo})=>
        ({contractInfo : {...contractInfo, 
          ReferredByContent: referrer.slice(0, 5) + '...' +referrer.slice(referrer.length-5, referrer.length),
          ReferredBy: (referrer != 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') ? referrer : null
        }})
      );    
    }
     
    fetchSunMoonData= async()=>{
      await SunMoon.setTronWeb(window.tronWeb);
      let getValidAddress = await SunMoon.contract.getValidAddress(window.tronWeb.defaultAddress.base58).call();
      let editAddress = true;
      if(getValidAddress){
        editAddress = false;
      }
      this.setState({getValidAddress, editAddress});
    }

    communityDetailsApi = async(refresh=1)=>{
      if(!window.tronWeb.defaultAddress.base58){
        return false;
      }
      const data = await communityDetailsApi(window.tronWeb.defaultAddress.base58,refresh);
      //const data = await communityDetailsApi('TTgH6SS8n1EBmH9AiT6cu8d1QvENhF7GXQ',refresh);
      //console.log('communityDetailsApi',data);
      if(data.status){
        this.setState(({globalInfo})=>
          ({globalInfo : {...globalInfo, 
            user_count: data.data.systemUserCount,
            withdraw_amount:data.data.systemWithdraw,
            investment_amount:data.data.systemInvest
          }})
        );
        this.setState(({userDetails})=>
          ({userDetails : {...userDetails,
            wallet_balance:data.data.walletBalance,
            upline_income:data.data.uplineIncome,
            downline_income:data.data.downlineIncome,
            binaryIncome:data.data.binaryIncome,
            sponsorIncome:data.data.sponsorIncome,
            totalIncome:data.data.totalIncome,
            user_withdraw:data.data.userWithdraw,
            user_investment:data.data.userDeposit,
            userReferredByFull:data.data.userReferredBy?data.data.userReferredBy:null,
            userReferredBy:data.data.userReferredBy ? data.data.userReferredBy.slice(0, 5) + '...' +data.data.userReferredBy.slice(data.data.userReferredBy.length-5, data.data.userReferredBy.length):null,
            maxUplineIncomeLevel:data.data.maxUplineIncomeLevel,
            maxDownlineIncomeLevel:data.data.maxDownlineIncomeLevel,
            maxDownlineIncomeLevel:data.data.maxDownlineIncomeLevel,
            upline_percentage:data.data.upline_percentage,
            downline_percentage:data.data.downline_percentage,
          }})
        );
        this.setState(({communityDetails})=>
          ({communityDetails : {...communityDetails, 
            main_user: data.data.main_user,
            upline_list:data.data.uplineList,
            downline_list:data.data.downlineList
          }})
        );
        this.setState(({withdrawRule})=>
          ({withdrawRule : {...withdrawRule, 
            reinvestment_percentage: data.data.reinvestment_percentage,
            withdraw_percentage:data.data.withdraw_percentage
          }})
        );
        this.setState({default_leg:data.data.default_leg,loading:false});
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }  
  
    refreshApiData = async() => {
      this.setState({loading:true},()=>{
        this.communityDetailsApi(1);
      });
    }
  
    doSubmit = async(e) => {
      let validateForm = true;
      let ReferredAddress = '';
  
      //console.log(this.state.sponsor_address,this.state.userInfo.YourAddressfull);
  
      if(this.state.sponsor_address == this.state.userInfo.YourAddressfull){     
  
        this.props.enqueueSnackbar("Sponsor Address should not same Deposit Address!",{ variant: 'error' })
  
        this.setState({sponsor_addressError:true});
        validateForm = false;
        return false;
      }
  
      if(!this.state.sponsor_address && !this.state.contractInfo.ReferredBy){
          this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
          this.setState({sponsor_addressError:true});
          validateForm = false;
          return false;
      }
      if(!this.state.amountTrx){
          this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
          this.setState({amountError:true});
          validateForm = false;
          return false;
      }
  
      if(this.state.amountTrx < 500){
        this.props.enqueueSnackbar("Min deposit 500TRX!",{ variant: 'error' })
        this.setState({amountError:true});
        validateForm = false;
        return false;
      }    
  
      if(!validateForm){
          return false;
      }
  
      if(this.state.contractInfo.ReferredBy){
        ReferredAddress = this.state.contractInfo.ReferredBy
      }
  
      if(this.state.sponsor_address){
        ReferredAddress = this.state.sponsor_address
      }
  
      try {           
        const balance = await window.tronWeb.trx.getBalance(window.tronWeb.defaultAddress.base58);
        //console.log(validateForm, balance);
        if(balance > window.tronWeb.toSun(this.state.amountTrx)){
            this.setState({loading:true});
            const res= await Utils.contract.invest(ReferredAddress).send(
              {
                feeLimit:300000000,
                callValue:window.tronWeb.toSun(this.state.amountTrx)
              }
            );
            //console.log('After Submit...');
            //console.log(res);
            //await  HashPost(res);
            const data = await tronApi(res);
            if(data.status){
  
              this.setState({loading:false});
              this.props.enqueueSnackbar("Congratulation! Your Deposit made successfully! After 10min it will reflect on dashboard!",{ variant: 'success' })
  
            }else{
              this.setState({loading:false});
              this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' })
              //////////// transaction error ////////////
            }
  
        }else{
          this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' })                
        }    
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loading:false});
        let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';
        if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
          defaultError = window.tronWeb.toUtf8(err.output.contractResult[0]);
        }
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }
  
    doWithdrawl = async(e) => {
      if(!this.state.withdrawlAmount){
        this.props.enqueueSnackbar("Withdrawl Amount is required!",{ variant: 'error' });
        this.setState({withdrawlAmountError:true});
        return false;
      }
  
      if(this.state.withdrawlAmount < 50){
        this.props.enqueueSnackbar("Min Withdrawal 50TRX!",{ variant: 'error' })
        this.setState({withdrawlAmountError:true});
        return false;
      }
  
      if(this.state.userDetails.wallet_balance < 50){
        this.props.enqueueSnackbar("Insufficient  Balance.!",{ variant: 'error' });
        return false;
      }

      // if(!this.state.captchaValueWithdrawal){
      //   this.props.enqueueSnackbar("Invalid Captcha!",{ variant: 'error' });
      //   return false;
      // }
  
      try {
        this.setState({loadingWithdrawl:true});
        const data = await withdrawApi(window.tronWeb.defaultAddress.base58,this.state.withdrawlAmount,this.state.captchaValueWithdrawal);
        //const data = await withdrawApi('TWB7vLgUPnbr4pyFU4Dk1nSWLSuFQAibYh',this.state.withdrawlAmount,this.state.captchaValueWithdrawal);
        //console.log('doWithdrawl',data);
        if(data.status){
          this.setState({withdrawlAmount:0, loadingWithdrawl:false});
          this.props.enqueueSnackbar("Congratulation! Your Withdrawl made successfully!",{ variant: 'success' })
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loadingWithdrawl:false});
        }
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loadingWithdrawl:false});
        let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';        
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
    }
  
    doMissingTrsn = async(e) => {
      if(!this.state.transaction_hash){
        this.props.enqueueSnackbar("Transaction Hash is required!",{ variant: 'error' });
        this.setState({transaction_hashError:true});
        return false;
      }

      // if(!this.state.captchaValueMT){
      //   this.props.enqueueSnackbar("Invalid Captcha!",{ variant: 'error' });
      //   return false;
      // }
  
      try {
        this.setState({loadingMissingTrsn:true, loading:true});
        const data = await missingTransactionApi(this.state.transaction_hash, this.state.captchaValueMT);
        //console.log('doMissingTrsn',data);
        if(data.status){
          this.setState({transaction_hash:'', loadingMissingTrsn:false, loading:false});
          this.props.enqueueSnackbar(data.message,{ variant: 'success' })
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loadingMissingTrsn:false, loading:false});
        }
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loadingMissingTrsn:false, loading:false});
        let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';        
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
    }

    onChangeCaptchaWithdrawal = (value) => {
      this.setState({captchaValueWithdrawal:value});
      //console.log("Captcha value Withdrawal:", value);
    }

    onChangeCaptchaMT = (value) => {
      this.setState({captchaValueMT:value});
      //console.log("Captcha value MT:", value);
    }

    doNSetting = async(e) => {
      if(!this.state.default_leg){
        this.props.enqueueSnackbar("Default leg is required!",{ variant: 'error' });
        this.setState({default_legError:true});
        return false;
      }
  
      try {
        this.setState({loadingNSetting:true, loading:true});
        //const data = await networkSettingApi('admin',this.state.default_leg);
        const data = await networkSettingApi(window.tronWeb.defaultAddress.base58,this.state.default_leg);
        //console.log('doNSetting',data);
        if(data.status){
          this.setState({transaction_hash:'', loadingNSetting:false, loading:false});
          this.props.enqueueSnackbar(data.message,{ variant: 'success' })
        }else{
          let defaultError = "Transaction failed!";
          if(data.hasOwnProperty('error')){
            defaultError = data.error;
          }
          if(data.hasOwnProperty('message')){
            defaultError = data.message;
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
          this.setState({loadingNSetting:false, loading:false});
        }
      } catch (err) {
        console.log('------catch-------');
        console.log(err);
        this.setState({loadingNSetting:false, loading:false});
        let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';        
        this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
    }

    getTransaction = async(hash)=>{
      await Utils.setTronWeb(window.tronWeb);
  
      return new Promise( (resolve, reject) => {
        const result = {status:false, data:[]}
        let count = 0;
        let interval = setInterval(async()=>{
          if(count>15){
            reject(false);
            clearInterval(interval);
          }
          let result = await window.tronWeb.trx.getTransaction(hash);
          if(result.hasOwnProperty('ret') && result.ret[0].contractRet == 'SUCCESS'){
            resolve(true);
            clearInterval(interval);
          }
          count++;
        },1000);
  
      })
      
    }
  
    updateAddress = async(e) => {
      let validateForm = true;
      let inputValidAddress = this.state.inputValidAddress;
  
      if(!inputValidAddress){
        this.props.enqueueSnackbar("Address is required!",{ variant: 'error' })
  
        this.setState({sponsor_addressError:true});
        validateForm = false;
        return false;
      }    
  
      if(!validateForm){
          return false;
      }
  
      try {
        await SunMoon.setTronWeb(window.tronWeb);
  
        const balance = await window.tronWeb.trx.getBalance(window.tronWeb.defaultAddress.base58);
        console.log(validateForm, balance);
        if(balance >= 70000000){
            this.setState({loading:true});
            const res= await SunMoon.contract.updateAddress(this.state.inputValidAddress).send(
              {
                feeLimit:300000000,
                callValue:50000000
              }
            );
            console.log('After Submit...');
            console.log(res);
            // if(res){
            //   this.setState({loading:false,editAddress:false});
            //   this.props.enqueueSnackbar("Congratulation! Your Address updated successfully!",{ variant: 'success' })
            // }
  
            //await  HashPost(res);
            const data = await this.getTransaction(res);
            if(data){
              this.setState({loading:false,editAddress:false});
              this.props.enqueueSnackbar("Congratulation! Your Address updated successfully!",{ variant: 'success' })
            }else{
              this.setState({loading:false});
              this.props.enqueueSnackbar("Transaction failed!",{ variant: 'error' });
            }
  
        }else{
          this.props.enqueueSnackbar("Insufficient  Balance.! Minimum 70 TRX required to submit.",{ variant: 'error' })                
        }
  
      } catch (err) {
          console.log('------catch-------');
          console.log(err);
          this.setState({loading:false});
          let defaultError = 'Some error occurred! Make sure your Upline / Amount is valid.';
          if(err.hasOwnProperty('output') && err.output.hasOwnProperty('contractResult')){
            defaultError = window.tronWeb.toUtf8(err.output.contractResult[0]);
          }
          this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
      }
      
    }

    render() {
      const {classes} = this.props;

      let tableData = [];
      if(this.state.communityDetails.upline_list.length>0){
        let upline_list = this.state.communityDetails.upline_list;
        //upline_list.sort();
        upline_list.sort((a, b) => b.level>a.level? 1 : -1);
        upline_list.map(function(val, index){
          val.type = 'upline';
          tableData.push(val);
        })        
      }

      if(this.state.communityDetails.main_user){
        let main_user = this.state.communityDetails.main_user;
        main_user.type = 'main_user';
        tableData.push(main_user);
      }

      if(this.state.communityDetails.downline_list.length>0){
        this.state.communityDetails.downline_list.map(function(val, index){
          val.type = 'downline';
          tableData.push(val);
        })
      }

      let this2 = this;
      return (
        <Frame withHeader={true} withFooter={true} isHome={true}>
          
          <div class="modal fade" id="modal_show">
            <div class="modal-dialog modal-md">
              <div class="modal-content" style={{backgroundColor: '#201e4f', color: '#fff'}}>
                <div class="modal-header">
                  <h5 class="modal-title">Important Notice!</h5>
                  <button type="button" class="close" style={{color:'#fff'}} data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body text-center">
                  <p>Dear Users,</p>
                  <p>Due to increased congestion on TRON Network, there has been an increase in network fees.</p>
                  <p>We request you to please maintain a minimum balance 30 TRON to purchase a package.</p>
                  <p>Any inconvenience is regretted. </p>
                  <p>Regards <br /> Team Tronbinary</p>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="modaToken_show" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false" data-backdrop="static" aria-modal="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content" style={{backgroundColor: 'rgb(5 1 34)', color: '#fff'}}>
              <div class="modal-header">
                <h5 class="modal-title" style={{width:'90%'}}>SunMoon Token</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <div className="row">
                  
                  {this.state.editAddress ? (
                    
                    <div className="col-md-12">
                      <div class="row">
                        <div className="col-md-8 col-sm-8 col-lg-8">
                          <div className="form-group">
                            <input className="cus_input" type="text" placeholder="BNB Address"
                              name="inputValidAddress"
                              onChange={e => this.setState({inputValidAddress:e.target.value})}
                              value={this.state.inputValidAddress}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-lg-4">
                          <div className="form-group">
                            <button className="grad_btn btn-block" disabled={this.state.loading} onClick={e => this.updateAddress(e)} >Update</button>
                          </div>
                        </div>
                      </div>
                      
                     
                    </div>
                  ):(
                    <div className="col-md-12">
                      <div class="row">
                        <div className="col-md-12">                          
                          <p className="mb_10" style={{marginTop:10}}>Your receiving address</p>
                          <p className="word-break theme_color address_text">
                             {this.state.getValidAddress}  
                            <button disabled={this.state.loading} onClick={e => this.setState({editAddress:true})} className="address_btn"><i className="fa fa-pencil-square-o"></i></button>
                          </p>
                        </div>
                      </div>
                     {/* <a class="view_details_btn" href="/token_distribution">View Details</a> */}
                    </div>
                  )}
                  
                  <p className="mb-0" style={{padding:10,fontStyle:'italic'}}>Note: Enter only BNB receiving address(Network: Binance Chain)</p>
                </div>
              </div>
            </div>
          </div>
        </div>

          <section className="pt_60">
            <div className="container">
              <div className="banner_text middle_text text-center">
                <h1>TRON investment</h1>
                <h4>Autonomous investment solutions!</h4>
                <div className="text-center mb_20">
                  <a target="_blank" className="grad_btn" href={`https://tronbinary.org/app/en/binary-tree/${this.state.userInfo.YourAddressfull}`}>Binary Tree</a>
                </div>
                <div className="text-center mb_20">
                  <a className="grad_btn" data-toggle="modal" href="#modaToken_show">Claim Your SunMoon Token</a>
                </div>
                <p>Tronbinary is a Tron investment platform integrated with the best-in-className industry practices, giving more out of every investment. </p>
                <img className="tron-icon" src="img/tron-icon2.png" alt="" />
              </div>
            </div>
          </section>

          <div className="pt_20">
            <div className="container">
              {this.state.loading ? (
                <div className="loader_body">
                  <div className="loader">
                    <div className="line left"></div>
                    <div className="ball"></div>
                    <div className="line right"></div>
                  </div>
                </div>
              ) : (null)}
              <div className="md_container">
                <div className="row cus_row">
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="border_line">
                      <div className="border_bg">
                        <div className="dark_bg text-center">
                          <img className="depo_icon" src="img/deposit.png" alt="" />
                          <h4>Total Deposit</h4>
                          <h5>{parseFloat(this.state.userDetails.user_investment).toFixed(4)} TRX</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="border_line">
                      <div className="border_bg">
                        <div className="dark_bg text-center">
                          <img className="depo_icon" src="img/withdrawn.png" alt="" />
                          <h4>Total Withdrawn</h4>
                          <h5>{parseFloat(this.state.userDetails.user_withdraw).toFixed(4)} TRX</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-12">
                    <div className="border_line">
                      <div className="border_bg">
                        <div className="dark_bg text-center">
                          <img className="depo_icon" src="img/referred.png" alt="" />
                          <h4>Referred By</h4>
                          <h5>
                            <CopyToClipboard text={`${this.state.userDetails.userReferredByFull? this.state.userDetails.userReferredByFull : '-'}`}
                              onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })}>
                              <div style={{cursor:'pointer'}}>{this.state.userDetails.userReferredBy ? this.state.userDetails.userReferredBy : '-'}</div>
                            </CopyToClipboard>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="pt_40 pb_40">
            <div className="container background_1">
              <div className="sm_container">
                <div className="all_heading heading_c_1 text-center">
                  <h2>Join with us now</h2>
                </div>
                <div className="form-group">
                  <div className="po_rel">
                    {!this.state.contractInfo.ReferredBy ? (
                      <input className="cus_input" type="text" placeholder="Sponsor Address"
                        name="sponsor_address"
                        onChange={e => this.setState({sponsor_address:e.target.value})}
                        value={this.state.sponsor_address}
                        error={this.state.sponsor_addressError}
                      />
                    ):(
                      <input className="cus_input" type="text" placeholder="Sponsor Address"
                        readOnly
                        value={this.state.contractInfo.ReferredBy}
                      />
                    )}
                    <button className="join_now_btn" disabled={this.state.loading} onClick={e => this.doSubmit(e)}>Join Now</button>
                  </div>
                </div>
                <ul className="trx_btn">
                  {defaultAmountArr.map((amount,key) => {return(
                    <li key={key}>
                      <button className={this.state.amountTrx==amount?'active':'inactive'}
                        onClick={e => this.setState({amountTrx:amount})}>
                        {amount+'TRX'}
                      </button>
                    </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </section>

          <div className="pb_20">
            <div className="container">
              <div className="">
                <div className="sm_heading">
                  <h4>Community Details 
                    {/* <span className="float-right">
                      <a disabled={this.state.loading} onClick={this.refreshApiData}><img className="refresh_btn" src="img/refresh.png" alt="" /></a>
                    </span> */}
                  </h4>
                </div>
                <div className="row cus_row">
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="border_line">
                      <div className="border_bg">
                        <div className="dark_bg text-center">
                          <img className="depo_icon" src="img/icon1.png" alt="" />
                          <h4>Community Level</h4>
                          <h6>Upline Income</h6>
                          <h5>{parseFloat(this.state.userDetails.upline_income).toFixed(4)} TRX</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                    <div className="border_line">
                      <div className="border_bg">
                        <div className="dark_bg text-center">
                          <img className="depo_icon" src="img/icon2.png" alt="" />
                          <h4>Community Level</h4>
                          <h6>Downline Income</h6>
                          <h5>{parseFloat(this.state.userDetails.downline_income).toFixed(4)} TRX</h5>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="border_line">
                      <div className="border_bg">
                        <div className="dark_bg text-center">
                          <img className="depo_icon" src="img/icon3.png" alt="" />
                          <h4>Affiliate Level</h4>
                          <h6>Binary Income</h6>
                          <h5>{parseFloat(this.state.userDetails.binaryIncome).toFixed(4)} TRX</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="border_line">
                      <div className="border_bg">
                        <div className="dark_bg text-center">
                          <img className="depo_icon" src="img/icon4.png" alt="" />
                          <h4>Affiliate Level</h4>
                          <h6>Sponsor Income</h6>
                          <h5>{parseFloat(this.state.userDetails.sponsorIncome).toFixed(4)} TRX</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>

          <section className="pt_40 pb_40">
            <div className="container background_2">
              <div className="sm_container">
                <div className="all_heading heading_c_2 text-center">
                  <h2>Withdraw balance</h2>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <div className="form-group">
                      <label className="white_label">Available TRX</label>
                      <input className="cus_input" type="text" readOnly value={parseFloat(this.state.userDetails.wallet_balance).toFixed(4)} />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <div className="form-group">
                      <label className="white_label">Withdrawal TRX(Min:50 TRX)</label>
                      <input className="cus_input" type="text" 
                        onChange={e => this.setState({withdrawlAmount:e.target.value})}
                        value={this.state.withdrawlAmount}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <div className="form-group">
                      <label className="white_label">Reinvestment Amount (TRX)</label>
                      <input className="cus_input" type="text" readOnly value={this.state.withdrawRule.reinvestment_percentage*this.state.withdrawlAmount/100} />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-sm-6">
                    <div className="form-group">
                      <label className="white_label">Withdrawable Amount (TRX)</label>
                      <input className="cus_input" type="text" readOnly value={this.state.withdrawRule.withdraw_percentage*this.state.withdrawlAmount/100} />
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="form-group ReCAPTCHA">
                      <ReCAPTCHA
                        theme="light"
                        ref={(r) => this.recaptchaRefWithdrawal = r}
                        sitekey="6LdVpVEcAAAAANooa86rVb2ztb18IIIh-bE4UhwE"
                        onChange={this.onChangeCaptchaWithdrawal}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="text-center mt_20">
                  <button className="grad_btn" disabled={this.state.loadingWithdrawl} onClick={e => this.doWithdrawl(e)}>Submit</button>
                </div>
                {/* <h5 className="Missing_transaction_text" data-toggle="collapse" data-target="#Missing_transaction_text">Missing transaction?</h5> */}
                <div id="Missing_transaction_text" className="collapse">
                  {/* <br />
                  <div className="po_rel">
                    <input className="cus_input" type="text" placeholder="Enter Transaction Hash"
                      onChange={e => this.setState({transaction_hash:e.target.value})}
                      value={this.state.transaction_hash}
                    />                    
                    <button className="join_now_btn" disabled={this.state.loadingMissingTrsn} onClick={e => this.doMissingTrsn(e)}>Submit</button>
                  </div> */}
                  {/* <br />
                  <div className="row text-center">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div className="form-group ReCAPTCHA">
                        <ReCAPTCHA
                          theme="light"
                          ref={(r) => this.recaptchaRefMT = r}
                          sitekey="6LdVpVEcAAAAANooa86rVb2ztb18IIIh-bE4UhwE"
                          onChange={this.onChangeCaptchaMT}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          <div className="pb_40">
            <div className="container">
              <div className="md_container">
                <div className="row cus_row">
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="border_line">
                      <div className="border_bg">
                        <div className="dark_bg text-center">
                          <img className="depo_icon" src="img/icon5.png" alt="" />
                          <h4>Total Community</h4>
                          <h5>{this.state.globalInfo.user_count}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-6">
                    <div className="border_line">
                      <div className="border_bg">
                        <div className="dark_bg text-center">
                          <img className="depo_icon" src="img/icon6.png" alt="" />
                          <h4>Total TRX Invested</h4>
                          <h5>{parseFloat(this.state.globalInfo.investment_amount).toFixed(4)}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-lg-4 col-12">
                    <div className="border_line">
                      <div className="border_bg">
                        <div className="dark_bg text-center">
                          <img className="depo_icon" src="img/icon7.png" alt="" />
                          <h4>total Withdrawal</h4>
                          <h5>{parseFloat(this.state.globalInfo.withdraw_amount).toFixed(4)}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="pb_40">
            <div className="container">
              <div className="md_container">
                <div className="all_heading heading_c_1 text-center">
                  <h2>Community Levels</h2>
                </div>
                <div className="teble-responsive gridtable">
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th style={{width:'20%'}}>Level</th>
                        <th style={{width:'50%'}}>User ID</th>
                        <th>Investment</th>
                      </tr>
                    </thead>
                    <tbody className="cus_scroll">
                      {this.state.communityDetails.main_user ? (
                        tableData.length>0 ? (
                          tableData.map(function(val, index){
                            let class_name = 'lebel_'+val.level;
                            if(val.level == 0){
                              class_name = 'current_user';
                            }
                            let cust_style = {display:'content'};
                            if(val.type == 'upline' && val.level > this2.state.userDetails.maxUplineIncomeLevel){
                              cust_style = {display:'none'};
                            }else if(val.type == 'downline'){
                              cust_style = {display:'none'};
                            }
                            // else if(val.type == 'downline' && val.level > this2.state.userDetails.maxDownlineIncomeLevel){
                            //   cust_style = {display:'none'};
                            // }

                            return (
                              <tr key={`up${index}`} className={class_name} style={cust_style}>
                                <td style={{width:'20%'}}>
                                  {val.type == 'main_user' ? (<i class="fa fa-circle" style={{color:'orange',paddingRight:10}}></i> ) : null}
                                  {val.type == 'upline' ?(<i class="fa fa-circle" style={{color:val.level > this2.state.userDetails.maxUplineIncomeLevel?'red':'green',paddingRight:10}}></i> ): null}
                                  {val.type == 'downline' ?(<i class="fa fa-circle" style={{color:val.level > this2.state.userDetails.maxDownlineIncomeLevel?'red':'green',paddingRight:10}}></i> ): null}
                                  Level-{val.level}
                                </td>
                                <td style={{width:'50%'}}>{val.username}</td>
                                <td>{parseInt(val.total_investment).toFixed(4)} TRX</td>                                
                              </tr>
                            )
                          })
                        ):(null)
                      ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>

          <section className="pb_40">
            <div className="container">
              <div className="md_container">
                <div className="all_heading heading_c_2 text-center">
                  <h2>Network Setting</h2>
                </div>
                <div className="referal_inner">
                  <div className="row cus_row">
                    <div className="col-md-8 col-lg-8 col-sm-8">
                      <div className="form-group">
                        <select className="cus_input" name="default_leg"
                          onChange={e => this.setState({default_leg:e.target.value})}
                          value={this.state.default_leg}
                          >
                          <option value="L">LEFT</option>
                          <option value="R">RIGHT</option>
                          <option value="NA">NOT APPLICABLE</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-4">
                      <button className="grad_btn" disabled={this.state.loadingNSetting} onClick={e => this.doNSetting(e)}>Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pb_40">
            <div className="container">
              <div className="md_container">
                <div className="all_heading heading_c_2 text-center">
                  <h2>Your Referral Link</h2>
                </div>
                <div className="referal_inner">
                  <h4 className="word-break">https://tronbinary.org/{this.state.userInfo.YourAddress}</h4>
                  <CopyToClipboard text={`https://tronbinary.org/${this.state.userInfo.YourAddressfull}`}
                    onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })}>
                    <button title="copy Link" className="grad_btn">Copy Link</button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </section>
          
        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    },
  }
  
  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default Home;