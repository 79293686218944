const contractAddress = 'TRezjBm8egri9omvGGTnKRLtFHBQKkG9nq';

export const utils = {

    tronWeb: false,
    contract: false,
    address: contractAddress,
    async setTronWeb(tronWeb) {
        this.tronWeb = tronWeb;
        this.contract = await tronWeb.contract().at(contractAddress)
    },

};

const contractAddressSunMoon = 'TT58U1TTRiM2rTmRWDRR6UegLtginRr7UZ';

export const SunMoon = {
    tronWeb: false,
    contract: false,
    address: contractAddressSunMoon,
    async setTronWeb(tronWeb) {
        this.tronWeb = tronWeb;
        this.contract = await tronWeb.contract().at(contractAddressSunMoon)
    },
};


export const tronApi = (hash) =>{
    
    const url = 'https://api.trongrid.io/v1/transactions/'+hash+'/events';
    //const url = 'https://api.shasta.trongrid.io/v1/transactions/'+hash+'/events';
    const options = {method: 'GET', headers: {Accept: 'application/json'}};

    return new Promise( (resolve, reject) => {

            const result = {status:false, data:[]}
            setInterval(()=>{
                fetch(url, options)
            .then(res => res.json())
            .then((json)=>{

                if(json.data.length > 0){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
            
            },10000);

    })
    

}

export const communityDetailsApi = (address,refresh) =>{
    let param = '';
    if(refresh){
        param = '?refresh=1';
    }
    //const url = 'https://p1.cryptrum.com/api/index-details/'+address+param;
    const url = 'https://tronbinary.org/app/api/index-details/'+address+param;
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const dataDetailsApi = (address,fullUrl,type,page) =>{
    let url = '';
    if(fullUrl){
        url = fullUrl;
    }else{
        //url = 'https://p1.cryptrum.com/api/details-data/'+address;
        url = 'https://tronbinary.org/app/api/details-data/'+address;
        if(type){
            url = url+'?'+type+'='+page;
        }
    }
    
    const options = {method: 'GET', headers: {Accept: 'application/json'}};
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
           // setInterval(()=>{
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
        //},10000);
    })
}

export const withdrawApi = (address,amount,captchaValue) =>{
    //const url = 'https://p1.cryptrum.com/api/withdraw';
    const url = 'https://tronbinary.org/app/api/withdraw';
    let params = {
        address:address,
        amount:amount
    }

    var formData = new FormData();
    formData.append('address', address);
    formData.append('amount', amount);
    formData.append('g-recaptcha-response', captchaValue);

    const options = {
        method: 'POST',
        headers: {Accept: 'application/json'},
        body:formData
    };
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                console.log('json',json);
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('amount')){
                        result.error = json.errors['amount'];
                    }
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('address')){
                        result.error = json.errors['address'];
                    }
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
    })
}

export const missingTransactionApi = (t_hash,captchaValue) =>{
    //const url = 'https://p1.cryptrum.com/api/missing-transaction';
    const url = 'https://tronbinary.org/app/api/missing-transaction';

    var formData = new FormData();
    formData.append('t_hash', t_hash);
    formData.append('g-recaptcha-response', captchaValue);
    const options = {
        method: 'POST',
        headers: {Accept: 'application/json'},
        body:formData
    };
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                console.log('json',json);
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    result.message = json.message;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('t_hash')){
                        result.error = json.errors['t_hash'];
                    }
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
    })
}

export const networkSettingApi = (address,default_leg) =>{
    const url = 'https://tronbinary.org/app/api/network-settings';
    let params = {
        address:address
    }

    var formData = new FormData();
    formData.append('address', address);
    formData.append('default_leg', default_leg);
    const options = {
        method: 'POST',
        headers: {Accept: 'application/json'},
        body:formData
        //body:JSON.stringify(params)
    };
    return new Promise( (resolve, reject) => {
            const result = {status:false, data:[]}
            fetch(url, options)
            .then(res => res.json())
            .then((json)=>{
                console.log('json',json);
                if(json.status == 'true'){
                    result.status = true;
                    result.data = json.data;
                    result.message = json.message;
                    resolve(result);
                }else{
                    if(json.hasOwnProperty('errors') && json.errors.hasOwnProperty('t_hash')){
                        result.error = json.errors['t_hash'];
                    }
                    if(json.hasOwnProperty('error')){
                        result.error = json.error;
                    }
                    if(json.hasOwnProperty('message')){
                        result.error = json.message;
                    }
                    resolve(result);
                }
            })
            .catch(err => console.error('error:' + err));
    })
}
